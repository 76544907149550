import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import Banner from './Banner/Banner';
import {
  ButtonWrapper,
  DescriptionWrapper,
  InnerWrapper,
  TextWrapper,
} from './styles';
import HomepageText from './constants';

const Homepage = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <PageWrapper title={PageTitles.Homepage}>
      <InnerWrapper>
        <Banner />
        <TextWrapper>
          <Typography variant="h1">{HomepageText.title}</Typography>
          <DescriptionWrapper>
            {HomepageText.descriptions.map((description) => (
              <Typography>{description}</Typography>
            ))}
          </DescriptionWrapper>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              navigate('/faq-and-contact');
            }}
          >
            Book a session
          </Button>
        </ButtonWrapper>
      </InnerWrapper>
    </PageWrapper>
  );
};
export default Homepage;
