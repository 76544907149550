import styled from 'styled-components';
import BannerBackground from '../../../assets/HomepageBanner.jpg';

export const BannerWrapper = styled.section`
  position: relative;
  width: 90%;
  height: 25em;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${BannerBackground});
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  padding: 0 5%;
  @media (max-width: 768px) {
    height: 23em;
  }
  q {
    font-size: 1.5em;
    text-align: justify;
    line-height: 1.5em;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`;

export const Author = styled.em`
  text-align: right;
  font-size: 1.5em;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
