import React, { ReactElement } from 'react';
import { Author, BannerWrapper } from './styles';

const Banner = (): ReactElement => (
  <BannerWrapper>
    <q>
      When I have been listened to and when I have been heard. I am able to
      re-percieve my world in a new way and to go on. It is astonishing how
      elements that seem insoluable become soluable when someone just listens…
    </q>
    <Author>- Carl R Rogers</Author>
  </BannerWrapper>
);

export default Banner;
