const HomepageText = {
  title: 'Counselling: A Safe and Supportive Space for You',
  descriptions: [
    "Taking the first step towards counselling can feel overwhelming, but it is also a brave and empowering decision. It's normal to feel uncertain, and I want you to know that you're not alone in this journey. Counselling provides a safe, confidential, and non-judgmental space where you can explore your thoughts, emotions, and experiences at your own pace.",
    'Whether you are facing anxiety, relationship challenges, grief, stress, or simply seeking greater self-awareness, therapy can help you process and understand your feelings. My role is to walk beside you, providing compassionate support without judgment or unwanted advice. This is your time, and you are in control of what you choose to share and explore.',
    'In our sessions, you will be truly listened to — without interruption or critique. You will be heard for who you are, and your feelings will be validated. Together, we can uncover the roots of your struggles, gain new perspectives, and work towards solutions that resonate with your unique needs.',
    'You deserve to feel seen, understood, and empowered. Counselling is a journey of self-discovery, healing, and growth, and I am here to support you every step of the way.',
    "No matter what you're going through, there is always a path forward. I'm here to help you find it.",
  ],
};

export default HomepageText;
