import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  p {
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
    font-size: 1.1em;
  }
  &.faq {
    background-color: #382336;
    color: #ffffff;
    border-bottom: solid 10px #2e192c;
    svg {
      color: #ffffff;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border-top: solid 5px #2e192c;

  display: flex;
  flex-direction: column;
  gap: 1em;

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }
  p {
    font-weight: 300;
  }
`;

export const Questions = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 90%;
`;

export const FAQTitle = styled.section`
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
