const ContactText = {
  title: 'Contact',
  descriptions: [
    'To get in contact with me, please fill in the contact form below',
    <>
      Alternatively, you can contact me via phone or Whatsapp at{' '}
      <a href="tel:07719968898">07719968898</a>
    </>,
    <>
      You can also contact me via email here:{' '}
      <a href="mailto:linda@ashcroftcounselling.co.uk">
        linda@ashcroftcounselling.co.uk
      </a>
    </>,
  ],
  faqTitle: 'Frequently Asked Questions',
  privacyPolicyTitle: 'Privacy Policy',
  privacyPolicyButtonText: 'Click here to view',
};

export default ContactText;
