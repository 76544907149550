import styled, { keyframes } from 'styled-components';
import Fab from '@mui/material/Fab';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const MainContent = styled.main`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  min-height: 81.2vh;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const Wrapper = styled.article`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
`;

export const StyledFab = styled(Fab)`
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  opacity: 0.7;
  color: #211229 !important;
  background: #5d3659 !important;
  &:hover,
  &:focus {
    opacity: 1;
  }
`;
