import CPD from './CPD/CPD';
import Experience from './Experience/Experience';
import Qualifications from './Qualifications/Qualifications';

export const AboutText = {
  title: 'Welcome to My Website',
  descriptions: [
    'Hello, and thank you for visiting. My name is Linda, and I am a fully qualified and experienced person-centred counsellor. I believe that every person has their own unique life experiences, strengths, and challenges. The person-centred approach to therapy, developed by renowned American psychologist Carl R. Rogers, is founded on the understanding that you are the expert of your own life. My role is to walk alongside you in your journey, offering a safe, empathic, and non-judgmental space where you can explore your thoughts, feelings, and concerns at your own pace.',
    "I truly believe that no two people are the same, and everyone faces life's difficulties in their own way. We all have our own coping mechanisms and ways of making sense of the world. My aim is to help you connect with your inner resources, gain a deeper understanding of yourself, and develop a greater sense of empowerment and control over your life. Life is full of ups and downs, and sometimes, the hardest part is knowing how to move forward. My approach is rooted in the belief that the answers are already within you. Together, we can explore how to accept and understand your situation and identify the steps that can help you find peace and healing.",
    "As a person-centred counsellor, I offer a space where you are truly heard. My goal is to create an atmosphere of acceptance, trust, and understanding, free from judgment and unwanted advice. I see our work together as a partnership—where you are in the driver's seat, and I am here to support you as you discover new ways to cope with life's challenges, understand your emotions, and make decisions that reflect your true self. Sometimes, it’s not about fixing the problem but about gaining clarity and finding a path that allows you to move forward with acceptance and a sense of purpose.",
    "The therapeutic process can be transformative, and it's a journey of growth, healing, and self-empowerment. You deserve a space where you can express yourself fully, without fear of judgment, and where your emotions are respected. I will be there to listen, reflect, and help you gain new insights that can lead to positive change in your life.",
  ],
};

export const PageSections = [
  {
    title: 'Qualifications',
    component: Qualifications,
  },
  {
    title: 'CPD',
    component: CPD,
  },
  {
    title: 'Experience',
    component: Experience,
  },
];
