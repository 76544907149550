import styled from 'styled-components';

export const AboutIntroSection = styled.div`
  p {
    line-height: 1.5;
    margin-bottom: 1em;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
`;

export const AboutMeDescription = styled.div`
  display: inline-block;
  width: calc(100% - 20rem);
  gap: 1em;
`;

export const LindaImg = styled.img`
  border-radius: 16px;
  max-height: 20rem;
  grid-area: img1;
  float: left;
  margin: 0 1em 1em 0;
  shape-outside: polygon(-7% -28%, 99% -28%, 99% 98%, -7% 98%);
  shape-margin: 1em;
  @media (max-width: 768px) {
    width: auto;
    shape-outside: 0;
    shape-margin: 0;
    float: unset;
    justify-self: center;
  }
`;
