import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  max-width: 100vw;
  background: #382336;
  width: 100vw;
  overflow: hidden;
`;

export const FooterContent = styled.section`
  color: #ffffff;
  display: flex;
  padding: 1em 2%;
  justify-content: space-between;
  width: 96%;
  p {
    margin: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 0.5em;
  }
`;

export const DetailsContainer = styled.section`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5em;
  flex-wrap: wrap;
  section {
    display: flex;
    align-items: center;
    gap: 0.3em;
    a {
      color: #ffffff;
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const CopyrightSection = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5em;
  flex-wrap: wrap;
  section {
    display: flex;
    align-items: center;
    gap: 0.3em;
    a {
      color: #ffffff;
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const BACPImg = styled.img`
  height: 4em;
  border-radius: 10px;
  border: solid 5px #5d3659;
  padding: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px #ffffff;
  align-self: center;
  @media (max-width: 768px) {
    width: 30%;
    height: unset;
  }
`;
