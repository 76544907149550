const CPDText = {
  title: 'Continual professional development',
  descriptions: [
    'These qualifications, along with my ongoing professional development, ensure that I am equipped to provide you with the highest standard of care and guidance. I believe in the power of continued learning and growth, which allows me to offer the most up-to-date and effective techniques and practices in therapy',
    "Whether you are struggling with anxiety, relationship difficulties, grief, or simply need support during a challenging time, I am here to help. If you're unsure whether counselling is right for you or if you have any questions about the process, please don't hesitate to reach out. Together, we can explore the possibilities for growth and healing that best suit your individual needs.",
    'I look forward to supporting you in finding your path to emotional well-being and a more empowered life.',
  ],
};

export default CPDText;
