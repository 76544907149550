import styled, { keyframes } from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  max-width: 100vw;
  background: #382336;
  width: 100vw;
`;

export const HeaderContent = styled.section`
  overflow: hidden;
  color: #ffffff;
  display: flex;
  width: 95%;
  padding: 1em 2%;
  /* width: 95%; */
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

export const LogoContainer = styled.section``;

export const Navigation = styled.nav`
  display: flex;
  gap: 1em;
  align-items: center;
  margin-right: 1em;
  a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HamburgerMenu = styled(MenuIcon)`
  cursor: pointer;
  &.menu {
    font-size: 2.5rem;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const slideInFromRight = keyframes`
  0% { transform: translateX(100%) }
  100% { transform: translateX(0) }
`;

const slideOutToRight = keyframes`
  0% { transform: translateX(0) }
  100% { transform: translateX(100% ) }
`;

export const MobileNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #382336;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.2em;
  overflow-x: hidden;
  animation: ${slideInFromRight} 0.3s forwards;
  z-index: 999;

  &.slideOut {
    animation: ${slideOutToRight} 0.3s forwards;
  }
`;

export const MobileNavLink = styled(NavLink)`
  width: 100%;
  padding: 1em 0;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  background: #3f293c;
  &:hover,
  &:focus,
  &.active {
    background: #b881f0;
  }
`;
export const MobileNavHeader = styled.section`
  display: flex;
  justify-content: flex-end;
  svg {
    font-size: 3em;
  }
`;

export const CloseMobile = styled(CloseIcon)`
  color: #ffffff;
`;

export const MobileNavLinks = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 3em;
  gap: 0.5em;
`;

export const LogoImg = styled.img`
  height: 100%;
  width: 6em;
  cursor: pointer;
`;
