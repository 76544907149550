import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SchoolIcon from '@mui/icons-material/School';

import FaceToFaceImg from '../../assets/Face-to-face.jpg';
import OnlineSessionImg from '../../assets/Online-session.jpg';
import PhoneSessionImg from '../../assets/Phone-session.jpg';

const ServicesAndFeesText = {
  title: 'Services And Fees',
  services: [
    {
      title: 'Face to face',
      img: FaceToFaceImg,
      desc: (
        <>
          <p>I am currently renting a room to provide this service.</p>
          <p>The room is very private and parking is available. </p>
          <p>Session will last for 60 minutes.</p>
        </>
      ),
      price: '£45.00',
    },
    {
      title: 'Online Counselling',
      img: OnlineSessionImg,
      desc: (
        <>
          <p>Counselling will take place over a secure platform.</p>
          <p>I will forward the links prior to the session.</p>
          <p>
            This is ideal for anyone who feels more comfortable in their own
            space.
          </p>
        </>
      ),
      price: '£45.00',
    },
    {
      title: 'Phone Counselling',
      img: PhoneSessionImg,
      desc: (
        <>
          <p>Counselling will take place over a phone call.</p>
          <p>
            This is ideal for anyone who feels more comfortable in their own
            space.
          </p>
        </>
      ),
      price: '£45.00',
    },
  ],
  concessions: [
    {
      icon: <CurrencyPoundIcon />,
      wording: 'Low Income',
    },
    {
      icon: <SchoolIcon />,
      wording: 'Students',
    },
    {
      icon: <LocalLibraryIcon />,
      wording: 'Trainee Counsellors',
    },
  ],
  openingTimes: [
    {
      index: 1,
      day: 'Monday',
      text: '9am - 5pm',
    },
    {
      index: 2,
      day: 'Tuesday',
      text: '9am - 7pm',
    },
    {
      index: 3,
      day: 'Wednesday',
      text: '9am - 5pm',
    },
    {
      index: 4,
      day: 'Thursday',
      text: '9am - 7pm',
    },
    {
      index: 5,
      day: 'Friday',
      text: '9am - 4pm',
    },
  ],
};

export default ServicesAndFeesText;
