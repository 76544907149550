import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import CPDText from './constants';

const CPD = (): ReactElement => (
  <>
    <Typography variant="h1">{CPDText.title}</Typography>
    {CPDText.descriptions.map((description) => (
      <Typography variant="body1">{description}</Typography>
    ))}
  </>
);
export default CPD;
