import { Typography } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import { ReactElement } from 'react';
import { Qualification, QualificationsContainer } from './styles';

const quals = [
  'NCFE Level 1 Award in interpersonal skills.',
  'ABC Award Level 2 Award in Counselling Concepts.',
  'ABC Level 3 Certificate in Counselling Skills.',
  'SEG Level 4 Diploma in Therapeutic Counselling.',
];

const Qualifications = (): ReactElement => (
  <>
    <Typography variant="h1">Qualifications</Typography>
    <QualificationsContainer>
      {quals.map((qual) => (
        <Qualification>
          <GradeIcon />
          <Typography>{qual}</Typography>
        </Qualification>
      ))}
    </QualificationsContainer>
  </>
);
export default Qualifications;
