import styled from 'styled-components';

export const PrivacyPolicyTitle = styled.section`
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  h2 {
    text-align: center;
  }
  a {
    color: #ffffff;
  }
`;
