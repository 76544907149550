import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Button, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';

import ServicesAndFeesText from './constants';
import {
  AvailabilityContainer,
  AvailabilityTitle,
  Concession,
  ConcessionsContainer,
  ConcessionsWrapper,
  Day,
  FreeMsg,
  InnerWrapper,
  OpeningSpan,
  OpeningTime,
  OpeningTimesContainer,
  Service,
  ServicesContainer,
  ServicesTitle,
  Time,
} from './styles';

const ServicesAndFees = (): ReactElement => (
  <PageWrapper title={PageTitles.ServicesAndFees}>
    <InnerWrapper>
      <ServicesTitle>
        <Typography variant="h1">{ServicesAndFeesText.title}</Typography>
      </ServicesTitle>
      <ServicesContainer>
        {ServicesAndFeesText.services.map((service) => (
          <Service key={service.title} backgroundImg={service.img}>
            <h2>{service.title}</h2>
            <>{service.desc}</>
            <p>Fee - {service.price} per session</p>
          </Service>
        ))}
      </ServicesContainer>

      <ConcessionsWrapper>
        <Typography variant="h1">Concessions offered for</Typography>
        <ConcessionsContainer>
          {ServicesAndFeesText.concessions.map((concession) => (
            <Concession key={concession.wording}>
              {concession.icon}
              <h2>{concession.wording}</h2>
            </Concession>
          ))}
        </ConcessionsContainer>
        <FreeMsg>
          <p>I do offer a FREE no obligation 20 minute consultation.</p>
          <p>
            This is where you can ask any questions, please feel free to contact
            me to arrange.
          </p>
          <p>EAP &amp; G.P. and other professional referrals welcome.</p>
        </FreeMsg>
      </ConcessionsWrapper>
      <AvailabilityContainer>
        <OpeningTimesContainer>
          <AvailabilityTitle variant="h1">
            <AccessTimeIcon />
            Availability
          </AvailabilityTitle>
          {ServicesAndFeesText.openingTimes.map((opening) => {
            const currentDay = new Date().getDay();
            return (
              <OpeningTime key={opening.day}>
                <Day isToday={Boolean(currentDay === opening.index)}>
                  <Typography variant="h2">{opening.day}</Typography>
                  {currentDay === opening.index ? (
                    <Typography variant="caption">Today</Typography>
                  ) : (
                    ''
                  )}
                </Day>
                <Time>
                  <p>{opening.text}</p>
                </Time>
              </OpeningTime>
            );
          })}
        </OpeningTimesContainer>
      </AvailabilityContainer>
      <OpeningSpan>
        <Link to="/faq-and-contact">
          <Button variant="outlined">Please contact for futher details</Button>
        </Link>
      </OpeningSpan>
    </InnerWrapper>
  </PageWrapper>
);
export default ServicesAndFees;
