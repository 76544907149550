import {
  Button,
  useTheme,
  Snackbar,
  Alert,
  AlertTitle,
  CircularProgress,
  Backdrop,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import sendMail from './ContactUtils';
import {
  FAQTitle,
  Questions,
  StyledAccordion,
  StyledAccordionDetails,
  StyledDetails,
} from './FAQStyles';
import {
  ContactContainer,
  ContactTitle,
  Email,
  Message,
  MessageHelperText,
  Name,
  PhoneNo,
  StyledForm,
  StyledInput,
  StyledTextarea,
  ContactIntro,
  FAQContactSection,
  InnerWrapper,
} from './styles';
import { ErrorMessages, EMAIL_REGEX } from './types';
import { PrivacyPolicy, PrivacyPolicyTitle } from './PrivacyPolicyStyles';
import PolicyInfo from '../../components/PolicyInfo/PolicyInfo';
import ContactText from './constants';

const questions = [
  {
    summary: 'How do I know if you are the right counsellor for me?',
    description: (
      <StyledDetails>
        <Typography>
          The quick answer is,{' '}
          <em>
            <strong>you don&apos;t</strong>
          </em>
          .
        </Typography>
        <Typography>
          I understand that starting therapy can feel like a big step, and
          it&apos;s important that you feel comfortable and confident in your
          decision. The therapeutic relationship between counsellor and client
          is the foundation on which we will build, and it is essential that you
          feel at ease with me and the process. That&apos;s why I offer a
          no-pressure, no-obligation 20-minute free consultation. This initial
          conversation allows you to ask any questions you may have and get a
          sense of how we might work together.
        </Typography>
        <Typography>
          During the consultation, you&apos;ll have the opportunity to share a
          bit about what&apos;s bringing you to therapy and explore whether my
          approach feels like a good fit for you. Whether you prefer a phone
          call or a Google Meets session, we can connect in the way that feels
          most comfortable for you
        </Typography>
        <Typography>
          This is your chance to decide if you feel comfortable with me, without
          any commitment. I want to ensure that you feel confident in moving
          forward and that the environment we create together will be one of
          trust, safety, and mutual respect.
        </Typography>
        <Typography>
          If, after our consultation, you feel that we&apos;re not the right
          fit, that&apos;s absolutely okay. My priority is for you to find the
          support and guidance that best suits your needs.
        </Typography>
      </StyledDetails>
    ),
  },
  {
    summary: 'How Long Will I Need to Attend Counselling?',
    description: (
      <StyledDetails>
        <Typography>
          The duration of counselling can vary greatly from person to person, as
          each individual&apos;s needs, goals, and circumstances are unique. I
          offer both short-term and long-term counselling, depending on what
          feels most appropriate for you and your situation.
        </Typography>
        <Typography>
          Some clients may find that a few sessions are sufficient to work
          through a specific challenge or issue, while others may prefer ongoing
          support over a longer period of time to explore deeper or more complex
          concerns. There is no one-size-fits-all approach, and the duration of
          therapy will be tailored to suit your individual needs.
        </Typography>
        <Typography>
          Throughout the process, I conduct regular reviews to assess how things
          are progressing. These reviews give us both the opportunity to reflect
          on the work we&apos;ve done together and discuss whether further
          sessions are needed. This is always done by mutual agreement, so you
          will never feel pressured to continue if you feel ready to take a
          break or close your sessions.
        </Typography>
        <Typography>
          The aim is always to ensure that you feel supported, and that
          we&apos;re working at a pace and length that works for you. If at any
          point you feel that you have achieved your goals or that further work
          is necessary, we will discuss the next steps together.
        </Typography>
      </StyledDetails>
    ),
  },
  {
    summary: "What if I can't make a session?",
    description: (
      <StyledDetails>
        <Typography>
          If you are unable to attend a scheduled session, please let me know as
          soon as possible. You can reach me via{' '}
          <strong>text, email, or voice message</strong>, whichever is most
          convenient for you.
        </Typography>
        <Typography>
          Please note that cancellations made with less than{' '}
          <strong>24 hours</strong> notice will incur a charge equivalent to the
          full session rate. This policy is in place to ensure that I can offer
          the time to other clients who may be in need of support, as well as to
          respect the time we&apos;ve set aside for our work together.
        </Typography>
        <Typography>
          I understand that sometimes unexpected circumstances arise, and I will
          always do my best to be understanding if a cancellation is necessary.
          However, I kindly ask that you give as much notice as possible to
          avoid this charge
        </Typography>
      </StyledDetails>
    ),
  },
];

const Contact = (): ReactElement => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [nameInvalid, setNameInvalid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoValid, setPhoneNoValid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [message, setMessage] = useState('');
  const [messageValid, setMessageValid] = useState<{
    invalid: boolean;
    message: ErrorMessages | null;
  }>({
    invalid: false,
    message: null,
  });
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const areErrors =
    nameInvalid.invalid ||
    emailInvalid.invalid ||
    phoneNoValid.invalid ||
    messageValid.invalid;
  return (
    <PageWrapper title={PageTitles.ContactFAQs}>
      <InnerWrapper>
        <FAQContactSection>
          <FAQTitle>
            <Typography variant="h1">{ContactText.faqTitle}</Typography>
          </FAQTitle>
          <Questions>
            {questions.map((question) => (
              <StyledAccordion className="faq">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{question.summary}</Typography>
                </AccordionSummary>
                <StyledAccordionDetails>
                  <Typography>{question.description}</Typography>
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </Questions>
        </FAQContactSection>
        <FAQContactSection>
          <PrivacyPolicyTitle>
            <Typography variant="h1">
              {ContactText.privacyPolicyTitle}
            </Typography>
          </PrivacyPolicyTitle>
          <PrivacyPolicy>
            <StyledAccordion className="faq">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{ContactText.privacyPolicyButtonText}</Typography>
              </AccordionSummary>
              <StyledAccordionDetails>
                <PolicyInfo />
              </StyledAccordionDetails>
            </StyledAccordion>
          </PrivacyPolicy>
        </FAQContactSection>
        <FAQContactSection>
          <ContactTitle>
            <Typography variant="h1">{ContactText.title}</Typography>
          </ContactTitle>
          <ContactIntro>
            {ContactText.descriptions.map((description) => (
              <p key={`${description}`}>{description}</p>
            ))}
          </ContactIntro>
          <ContactContainer>
            <StyledForm
              onSubmit={(e) => {
                e.preventDefault();
                if (name && email && message) {
                  setLoading(true);
                  // submit email but pass in checks in case it breaks
                  sendMail({
                    name,
                    email,
                    phoneNo,
                    message,
                    navigate,
                    setShowErrorSnackbar,
                    setLoading,
                  });
                } else {
                  if (!name) {
                    setNameInvalid({
                      invalid: true,
                      message: ErrorMessages.Required,
                    });
                  }
                  if (!email) {
                    setEmailInvalid({
                      invalid: true,
                      message: ErrorMessages.Required,
                    });
                  } else {
                    // there could be an email so check it is a valid email!
                    const validEmail = EMAIL_REGEX.test(email);
                    if (!validEmail) {
                      setEmailInvalid({
                        invalid: true,
                        message: ErrorMessages.BadEmail,
                      });
                    }
                  }
                  if (!message) {
                    setMessageValid({
                      invalid: true,
                      message: ErrorMessages.Required,
                    });
                  }
                }
              }}
            >
              <Name>
                <StyledInput
                  label="Name *"
                  variant="outlined"
                  color="primary"
                  id="name"
                  value={name}
                  onChange={(e) => {
                    setNameInvalid({
                      invalid: false,
                      message: null,
                    });
                    setName(e.target.value);
                  }}
                  error={nameInvalid.invalid}
                  helperText={nameInvalid.message}
                />
              </Name>
              <PhoneNo>
                <StyledInput
                  label="Phone number"
                  type="tel"
                  variant="outlined"
                  color="primary"
                  id="phoneNo"
                  value={phoneNo}
                  onChange={(e) => {
                    setPhoneNoValid({
                      invalid: false,
                      message: null,
                    });
                    setPhoneNo(e.target.value);
                  }}
                  error={phoneNoValid.invalid}
                  helperText={phoneNoValid.message}
                />
              </PhoneNo>
              <Email>
                <StyledInput
                  label="Email *"
                  variant="outlined"
                  color="primary"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmailInvalid({
                      invalid: false,
                      message: null,
                    });
                    setEmail(e.target.value);
                  }}
                  error={emailInvalid.invalid}
                  helperText={emailInvalid.message}
                />
              </Email>
              <Message>
                <StyledTextarea
                  placeholder="What's on your mind?*"
                  id="message"
                  value={message}
                  onChange={(e) => {
                    setMessageValid({
                      invalid: false,
                      message: null,
                    });
                    setMessage(e.target.value);
                  }}
                  className={messageValid.invalid ? 'error' : ''}
                />
                {messageValid.invalid && (
                  <MessageHelperText color={theme.palette.error.main}>
                    {messageValid.message}
                  </MessageHelperText>
                )}
              </Message>
              <Button
                disabled={areErrors}
                type="submit"
                color="primary"
                variant="outlined"
              >
                Send Message
              </Button>
            </StyledForm>
          </ContactContainer>
          {showErrorSnackbar && (
            <Snackbar
              open={showErrorSnackbar}
              autoHideDuration={10000}
              onClose={() => {
                setShowErrorSnackbar(false);
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert severity="warning">
                <AlertTitle>The message failed to send!</AlertTitle>
                Unfortunately something went wrong, please contact me directly
                here:{' '}
                <a href="mailto:linda@ashcroftcounselling.co.uk">
                  linda@ashcroftcounselling.co.uk
                </a>
              </Alert>
            </Snackbar>
          )}
          <Backdrop sx={{ color: '#ffffff', zIndex: '999' }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </FAQContactSection>
      </InnerWrapper>
    </PageWrapper>
  );
};
export default Contact;
