import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import Linda from '../../assets/Linda.jpg';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageTitles from '../../constants/PageTitles';
import { InnerWrapper, SubTextWrapper, TextWrapper } from '../Homepage/styles';
import { AboutIntroSection, LindaImg } from './styles';
import { AboutText, PageSections } from './constants';

const About = (): ReactElement => (
  <PageWrapper title={PageTitles.About}>
    <InnerWrapper>
      <TextWrapper>
        <Typography variant="h1">{AboutText.title}</Typography>
        <AboutIntroSection>
          <LindaImg src={Linda} alt="Linda Ashcroft" />
          {AboutText.descriptions.map((description) => (
            <Typography variant="body1">{description}</Typography>
          ))}
        </AboutIntroSection>
      </TextWrapper>
      {PageSections.map((section, index) => {
        if (index % 2 !== 0) {
          return (
            <TextWrapper key={section.title}>
              <section.component />
            </TextWrapper>
          );
        }
        return (
          <SubTextWrapper key={section.title}>
            <section.component />
          </SubTextWrapper>
        );
      })}
    </InnerWrapper>
  </PageWrapper>
);
export default About;
