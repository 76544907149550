import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const ServicesContainer = styled.section`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.81em;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export const Service = styled.section<{ backgroundImg: string }>`
  width: 30%;
  height: auto;
  aspect-ratio: 1/1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  transition: all 0.3s ease;
  text-align: center;
  padding: 0.5em;
  &:hover {
    scale: 1.01;
  }
  @media (max-width: 1200px) {
    width: 90%;
    aspect-ratio: unset;
    padding: 5%;
  }
`;

export const ServicesTitle = styled.section`
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }
`;

export const ConcessionsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;

  h1 {
    font-size: 1.8em;
    font-weight: 700;
    text-align: center;
  }
`;

export const ConcessionsContainer = styled.section`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5%;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export const Concession = styled.section`
  width: 10em;
  aspect-ratio: 1/1;
  background: #382336;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em auto;
  align-items: center;
  padding: 1.5em 2em;
  border-radius: 15px;
  text-align: center;
  h2 {
    font-size: 1.4em;
  }
  svg {
    font-size: 5em;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    width: 100%;
    aspect-ratio: unset;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FreeMsg = styled.section`
  text-align: center;
  max-width: 90%;
  p {
    margin: 0;
  }
  p:first-child {
    font-size: 1.2em;
    margin-top: 1em;
  }
`;

export const OpeningTimesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;

  padding: 1.5em 1em;
  width: 85%;

  border-radius: 24px;
  background-color: #382336;
`;

export const OpeningTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  padding: 0 1em;
  border-radius: 6px;
  background-color: #553a52;

  &:hover {
    background-color: #2f1e2d;
  }
`;

export const Day = styled.div<{ isToday: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  justify-content: center;
  align-items: flex-start;

  h2 {
    font-size: 1.1em;
    font-weight: 600;
  }

  ${(props) =>
    props.isToday &&
    css`
      h2 {
        margin-bottom: 0;
      }
    `}
`;

export const Time = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
`;

export const OpeningSpan = styled.span`
  margin-top: 0.5em;
  a {
    color: #ffffff;
  }
`;

export const InnerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 1em 0;
`;

export const AvailabilityContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5em;
  padding: 1em 0;
  width: 100%;

  h1 {
    font-size: 1.7em;
    font-weight: 700;
  }
`;

export const AvailabilityTitle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
